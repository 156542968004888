

.header-wrapper{
    display: flex;
    flex-flow: column;
    /* height: max-content; */
    /* grid-gap: 2px; */
    margin: 0px 2px;
    gap: 15px;
    background: white;
    padding: 8px 0px;
}
.header-sub-wrap{
    height: max-content;
    display: flex;
    flex-flow: row;
    gap: 112px;
}

.header-sub-wrap div{
 position: relative;
}

.brand-desc{
  display: flex;
    flex-direction: column;
    /* flex-flow: column; */
    align-items: flex-start;
    gap: 6px;
    margin: 0px 10px;
}

.brand-desc span{
  text-align: initial !important;
  font-family: 'prata',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  line-height: 18px;
}

.brand-desc a{
  margin-block-start: 1em;
  color: #101828;
    line-height: 28px;
    font-family: 'prata',serif;
    /* font-style: normal; */
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.nav-wrap{
  display: flex;
  gap: 15px;
  margin: 0px 10px;
}
.nav-heading{
  background: #D9D9D9;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    border-radius: 10px;
    padding: 4px;
    margin: auto;
    text-align: center;
    font-style: normal;
    width: 100%;
    /* height: 29px; */
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.nav-heading.active{
  color: #fff !important;
  background: #555555 !important;
  
}

@media screen and (min-width: 768px) {
    
  .header-wrapper{
    margin: auto;
    max-width: 400px;
  }
}

.sort-icon{
  margin-right: -8px;
}

.filter-icon{
  margin-top: 2px;
}