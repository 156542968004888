.fh-brand-logo{
  /* border-radius: 50%; */
  width: 50px;
  cursor: pointer;
  margin: 5px 3px;
}

.icons-div{
  display: flex;
  gap: 15px;
  align-items: center;

}


@media screen and (min-width: 768px) {
    
  .fixed-header-wrapper{
      background-color: #ffffff;
      margin-bottom: 25px; 
     
    }
}

.fh-brand-image{
  border: 0;
  font: inherit;
  font-size: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  border-radius: 50%;
  padding: 0;
  top: 0;
  vertical-align: baseline;
  width: 100%;
}

.fixed-header-wrapper{ 
  background-color: #ffffff;  
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0px 6px;
  padding-right: 15px;
  margin-bottom: 8px;
}

.fixed-header-sub-wrap{
  height: max-content;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.fixed-header-sub-wrap div{
 position: relative;
}

.brand-desc{
  display: flex;
    flex-direction: column;
    /* flex-flow: column; */
    align-items: flex-start;
    gap: 6px;
    margin: 0px 10px;
}

.brand-desc span{
  text-align: initial !important;
  font-family: 'prata',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  line-height: 18px;
}

.brand-desc a{
  margin-block-start: 1em;
  color: #101828;
    line-height: 28px;
    font-family: 'prata', serif;
    /* font-style: normal; */
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

.cart-count-header{
   border: 1.5px solid #393434;
    /* height: 30px; */
    border-radius: 7px;
    padding: 3px 4px 3px 4px;
    position: relative;
    display: inline-block;
    margin-left: 5px;
}

.cart-count-header img {
  vertical-align: middle;
}

.cart-count-header::after {
  content: attr(data-count);
    position: absolute;
    top: -8px;
    right: -8px;
    /* left: -10px; */
    font-size: 9px;
    font-weight: bold;
    background-color: #f00;
    color: #fff;
    border-radius: 50%;
    padding: 4px 5px 4px 7px;
}



