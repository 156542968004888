.video-comp{
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.rating-div{
    align-items: inherit;
    /* margin-right: 0px; */
    margin-left: 27px;
    display: flex;
    gap: 1px;
    font-size: 12.5px;
}

.scp-price{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
}

.scrolling-product-wrapper{
    /* border: 1px solid #ccc; */
    position: relative;
    padding: 3px;
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    gap: 3px;
}

.shop-all{
    border: 1px solid #000000;
    min-width: 90px;
   
    padding: 5px;
    margin: 5px 0px;
    margin-top: 1px;
    border-radius: 10px;
    height: 60px;
    display: flex;
    flex-flow: column;
    gap: 9px;
    align-items: center;
    justify-content: center;
}

.shop-all-btn{   
    display: flex;
    justify-content: center;
    /* padding-top: 7px; */
    align-items: center;
    text-align: center;
}

.shop-all a{
    color: #000;
    font-size: 12px;
    font-weight: 600;
}

.scp-wrapper{
    border: 1px solid #000000;
    min-width: 140px;
    width: 140px;
    padding: 5px;
    border-radius: 10px;
    height: 60px;
    display: flex;
    flex-flow: row;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
}

.scp-image-div{
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    /* min-width: 35%; */
    border-radius: 5px;
}

.scp-all-wrapper{
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: auto;
    /* position: relative; */
    padding: 5px;
    padding-top: 1px;
}
.scp-desc{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex-grow: 3;
    font-size: 10px;
    gap: 7px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 26%);
    z-index: 999;
    display: flex;
    backdrop-filter: blur(5px);   
    align-items: flex-end;
    justify-content: center;

   /* position: fixed;
    bottom: 20px;
    /* left: 0; 
    width: 100%;
    min-height: 60%;
    height: auto;
    z-index: 99;
    align-items: center;
    /* box-shadow: #11111ab8 0px 3px 0px, #11111a1a 0px 5px 8px; 
    border-radius: 24px;  */
  }
  
  .popup-content {
    position: absolute;
    background-color: #fff;
    width: 100%;
    max-height: 80vh; 
    overflow-y: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 7px 3px;
    border: 1px solid #ccc;

   /* position: absolute;
    background-color: white;
    /* padding: 20px; 
    border-radius: 5px;
    background: white;
    /* z-index: 999; */
    /* max-height: 50vh; */
    /* padding: 5px 0px; 
    box-shadow: 0 -2px 5px 0 #00000030, 0 6px 5px 0 #00000030;
    margin: 5px;
    padding-top: 5px;
    height: 100%;
    overflow: auto;
    */
}

.cat-popup-content{

    position: absolute;
    background-color: #fff;
    width: 100%;
    max-height: 80vh; 
    overflow-y: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 7px 3px;
    border: 1px solid #ccc;;

}

@media screen and (min-width: 768px) {
    
.popup{
    margin: auto;
    max-width: 400px;
}


  }
  
  .scp-all-wrapper::-webkit-scrollbar {
    width: 0; 
    height: 0;
  }
  
  .scp-all-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1; /* set the background color of the scrollbar track */
  }
  
  .scp-all-wrapper::-webkit-scrollbar-thumb {
    background-color: #888; /* set the color of the scrollbar thumb */
    border-radius: 0.25em; /* set the border radius of the scrollbar thumb */
  }
  
  
.popup-content a {
    cursor: pointer;
  }
 /*
  
  .video-comp div{
    display: flex;
    flex-flow: column;
    gap: 10px;
}
*/
.prod-vid-wrap{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 5px;
    margin-bottom: 10px;
}

.scp-brand-name{
    line-height: 15px;
    text-align: left;

}

.scp-brand-name span{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.close-popup-btn{
    text-align: right;
}

.close-popup-btn a{
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
}


