    
.prod-brand-info{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.prod-brand-logo{
   /* background-color: rgb(var(--ig-secondary-background));
    border-radius: 50%;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;*/

    height: 35px;
    border-radius: 50%;
    width: 37px;
    border: 1px solid #ccc;
}

.grey-dot{
    height: 8px;
    background: #ddd6d6a3;
    display: block;
    border-radius: 50%;
    width: 8px;
    border: 1px solid #ddd6d6a3;
}

.prod-brand-image{
    border: 0;
    font: inherit;
    font-size: 100%;
    height: 100%;
    left: 0;
    margin: 0;
    border-radius: 50%;
    padding: 0;
    top: 0;
    vertical-align: baseline;
    width: 100%;
}

.bheader-wrapper{
  /*  margin: 0px 13px;
    margin-bottom: 5px;
     box-shadow: 0px 2px 5px rgb(0 0 0 / 11%);*/

   
    padding: 8px 10px;
    padding-left: 15px;
    margin-bottom: 3px;
}

.prod-brand-title{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.pbt{
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
}

.prod-brand-name{
    font-size: 14px;
    font-weight: 600;
    
}

.uploaded-time{
    font-size: 12px;
    color: #6b6a6a;
}

.uploaded-time span{
    float: left;
}

.follow-span{
    font-size: 13px;
    font-weight: 700;
    /*color: #f30e36;*/
    color: #00a9ca;
    padding-bottom: 3px;
}

.followers-div{
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
}

.followers-div span{
    font-size: 14px;
    color: #7b7676;
    /* color: #847b7b; */
    /* font-family: 'Inter'; */
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}
