.App {
  background: #fff;
 /* display: flex;
  position: relative;
  justify-content: center; */
  color: #222222;
  flex-direction: column;
    font-family: "Source Sans Pro", sans-serif;;
    max-height: 100vh;
    overflow:hidden;
    text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrapper{
  display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
    max-height: 80vh;
    width: 100%; 
}

.wrapper-upper{
  display: flex;
  justify-content: center;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.page-content{
 max-height: 100vh;
  margin-bottom: 2px;

}

.wrapper::-webkit-scrollbar {
  width: 0; /* set the width of the scrollbar */
}

.wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; /* set the background color of the scrollbar track */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #888; /* set the color of the scrollbar thumb */
  border-radius: 0.25em; /* set the border radius of the scrollbar thumb */
}

@media screen and (min-width: 768px) {
  .App{
    background: #f9f9f9;
  }
  .wrapper {
    width: 100%;
    max-height: 80vh;
  }
  .wrapper-upper{
    width: 400px;
    background: #fff;
    height: 100vh;
    position: relative;
    margin: auto;

}
.cart-wrapper{
  width: 400px;
}

}

.cart-wrapper{
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
}

/*
@media (max-height: 680px) {
  .page-content {
    max-height: 68vh;
  }

  .wrapper{
    max-height: 90vh;
  }

}
*/
