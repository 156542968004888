
.carousel-wrapper {
    width: 100%;
    margin: 0 auto;
  }
  
  
  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    /* max-height: 68%; */
    max-width: 90%;
    margin: auto;
  }
 
  .carousel-slide img {
    max-width: 100%;
    max-height: 100%;
  }



  .alice-carousel__prev-btn,
.alice-carousel__next-btn {
  color: #fff;
  font-size: 33px;;
 
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.2s;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #ffffff;
}

.alice-carousel__prev-btn {
  left: 14px;
}

.alice-carousel__next-btn {
  right: 14px;

}

.alice-carousel__dots {
  display: none;
}