.page4-comp{
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.page4-prod-img{
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.page4-img-container{
    position: relative;
    max-width: 100%;
    height: auto;
    /* padding-bottom: 75%; */
    margin: 10px;
    margin-bottom: 0px;
}

