    
     .all-prods{
        display: grid;
        grid-gap: 2px;
        grid-template-columns: 1fr 1fr;
    }

    .product-wrapper{
        border: 1px solid #ccc;
        display: flex;
         flex-direction: column;
         max-width: 160px;
    }

    .prod-desc{
        text-align: initial;
        display: block;
        padding: 0px 7px;
        margin-bottom: 4px;
    }
    .prod-name{
        display: inline-block;
        font-weight: 600;
        font-size: 13px;
    }
    
    .actual-price{
        text-decoration: line-through;
        color:#535353;
        font-size: 10px;
        font-weight: 400;
    }

    .selling-price{
        font-weight: 600;
    font-size: 13px;
    }

    .prod-img a{
        width: 100%;
        display: block;
        z-index: 1;
        position: relative;
    }

    .imageContainer{
        background-repeat: no-repeat;
        background-size: cover;
        height: 210px;
        width: 100%;
    }

    .loadingAnimation {
        background: no-repeat #f1f1f1;
        width: 100%;
        -webkit-animation-duration: 1.5s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: loadingContainer;
        -webkit-animation-timing-function: linear;
        background-image: -webkit-gradient(linear,left top,right top,from(#f2f2f2),color-stop(50%,#eaeaea),to(#f2f2f2));
        background-image: linear-gradient(to right,#f2f2f2,#eaeaea 50%,#f2f2f2);
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loadingContainer;
    }

    @keyframes loadingContainer {
        0% {
            background-position: -800px 0;
        }
        100% {
            background-position: 800px 0;
        }
      }

    .prod-metadata{
        font-size: 12px;
        color: #434343;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dis-per{
        color: #af5858;
    font-size: 13px;
    }
      