
.footer-wrapper {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #fff;
    z-index: 1;
    padding-bottom: 7px;
}

.option-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
    
    margin: 0px 6px;
}

.option-wrapper a{
    background: #2e2601b8;
    color: #fff;
    padding: 7px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    gap: 5px;
}

.sort-popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 32%);
    backdrop-filter: blur(5px);
    z-index: 999;
    display: flex;
    align-items: flex-end;
    justify-content: center;
   
}

.sort-popup-content{   
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 17px;
    /* font-weight: 400; */
    line-height: 20px;
    /* margin-block-start: 0; */
    gap: 10px;
    padding-inline-start: 17px;
}

.sort-popup-content-wrapper{
    background-color: #fff;
    width: 100%;
    /* max-height: 80vh; */
    overflow-y: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 7px;
}

.sort-popup-content li{   
    cursor: pointer;
    padding: 4px 0;
    color: #727272;
    font-weight: 500;
}

.sort-popup-content li:hover{   
    font-weight: 700;
}

.li-active{
    font-weight: 700 !important;
}
/*
.sort-popup-header{
    font-weight: 600;
    font-size: 16px;
    color: #344054;
    padding: 12px;
    padding-top: 15px;
    border-bottom: 1px solid #e3e2e2;
    margin: 0px 17px;
}

.sort-popup-header a{
    float: right;
    
}
*/

.sort-popup-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;
    font-size: 15px;
    color: #344054;
    margin: 0px 12px;
    padding: 15px 5px;

}


@media screen and (min-width: 768px) {
    .footer-wrapper {
        width: 401px;
    }
    
.sort-popup{
    margin: auto;
    max-width: 400px;

}
  }

  .blue-dot{
    height: 4px;
    background: #00344ce3;
    display: block;
    border-radius: 50%;
    width: 4px;
    border: 1px solid #00344ce3;
}



