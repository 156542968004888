.video-player-wrapper {
    position: relative;
    width: 65%;
    margin: auto;
  }

  .video-player-wrapper div{
    margin: auto;
  }

  

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.play-button::before {
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
}
  
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* New CSS for the mute button #C0C0C0 */
.mute-button {
  position: absolute;
    bottom: 10px;
    right: 28px;
    cursor: pointer;
    z-index: 1;

}

/* Style for the mute button when it's clicked */
