.page3-comp {
    display: flex;
    flex-flow: column;
    margin: 5px 10px;
    gap: 5px;
}
.prod-cover-img{
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
}

.prod-other-img{
    display: flex;
    flex-flow: row;
    gap: 5px;
}

.partition-line{
    border-top: 1px solid #ccc;
    width: 100%;
    /* margin: 5px 0px; */
    margin-top: 6px;
}

.page3-prod-info{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    text-align: left;
    gap: 5px;
}

.page3-brand-name{
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
}

.page3-prod-detail{
    font-size: 14px;
    color: #3d3d3d;
    display: block;
    line-height: 18px;
}

.page3-rating{
    border: 1px solid #ccc;
    padding: 1px 10px;
    font-size: 13px;
}

.page3-prod-img{
    width: 100%;
    height: auto;
    border-radius: 7px;
}

.page3-actual-price{
    text-decoration: line-through;
    color:#535353;
    font-size: 10px;
    font-weight: 400;
}

.page3-selling-price{
    font-weight: 800;
font-size: 14px;
}

.product-info-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 8px;
}

.taxes{
    font-size: 12px;
    color: #00cf00;
}

.page3-heading{
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
}

.product-info-sub-wrapper{
    display: grid;
    grid-gap: 13px;
    grid-template-columns: 1fr 1fr;
}
.product-info-sub-wrapper div{
    display: flex;
    flex-flow: column;
    gap: 5px;
}
.page3-sub-wrapper{
    display: flex;
    flex-flow: column;
    gap: 2px;
}

.info-heading{
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #475467;
}

.info-value{
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #262d37;
}

.page3-desc-span{
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    white-space: pre-line;
    color: #3d3d3d;
}

.buy-now{
    width: 100%;
    margin: auto;
    color: #fff;
    background: #555555;
    padding: 6px 0px;
    font-weight: 500;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
}

.disabled{
    background: #5555558c !important;
}
.checkout-btn-wrapper{
    display: flex;
    gap: 9px;
    margin-top: 15px;
}

.page3-sub-heading{
    font-size: 13px;
    font-weight: 500;
}

.variant-div{
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.size-list{
    font-size: 12px;
    display: flex;
    flex-flow: row;
    gap: 10px;
}

.size-list a{
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px; 
    height: 15px;
    width: 15px;
    text-align: center;
}

.color-list{
    display: flex;
    flex-flow: row;
    gap: 10px;
}

.color-list a{
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px; 
    height: 15px;
    width: 15px;
}

.size-list a.selected,
.color-list a.selected {
  border: 2px solid #6bb2ff;
}

.cart-count{
    border: 1.5px solid #393434;
    /* height: 30px; */
    border-radius: 7px;
    padding: 5px 5px 4px 5px;
    position: relative;
    display: inline-block;
    margin-left: 5px;
}

.cart-count img {
    vertical-align: middle;
  }
  
  .cart-count::after {
    content: attr(data-count);
    position: absolute;
    top: -8px;
    right: -8px;
    /* left: -10px; */
    font-size: 9px;
    font-weight: bold;
    background-color: #f00;
    color: #fff;
    border-radius: 50%;
    padding: 4px 6px 4px 7px;
  }